
.licensingHistory {
    margin: 0;
    padding: 20px 20px;
    background-color: white;
    border: 1px solid  rgb(210, 210, 210);
    border-radius: 5px;
}

.licensingHistory p {
    margin-top: 7px;
}

.licensingHistory button {
    margin-left: 10px;
}

.licensingHistory--icons svg {
    margin-top: -6px;
    /*margin-right: 5px;*/
}

.licensingHistory--back {
    margin-bottom: 10px;
    display: flex;
    justify-content: end;
}

.alert-block {
    position: absolute !important;
    bottom: 20px;
    width: 60%;
    text-align: center;
}

.LicensingHistoryModalSubTable {
    margin: 20px 20px;
}