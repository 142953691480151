.loader {
  align-items: center;
  text-align: center;
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  background: rgba(0,0,0,0);
  border: 0;
  filter: drop-shadow(0px 0px 20px black);
}

.loader .anticon {
  margin: 0 auto;
  font-size: 100px;
}


