.assets-content{
    padding: 30px 20px 40px 30px;
    background-color: #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.GRRTable table {
   /* margin-top: 20px*/
}

.GRRTable td{
    cursor: pointer;
}

.GRR--actionButtons {
    margin-top: 20px;
}

.GRR--actionButtons p {
    font-size: 18px;
    margin-bottom: 5px;
}
.GRR--actionButtons button:disabled {
    opacity: 0.3;
}

.GRR--actionButtons button {
    width: 80%;
    margin-top: 10px;
    box-shadow: none;
}

.GRR--actionButtons button:focus {
    box-shadow: none;
}

.grr--header__block {
    /*justify-content:left;*/
}

.grr--header__block button {
    height: 2.5rem;
}

.grr--header__stageBlock {
    margin-bottom: 30px;
}

.grr--header__historyButton {
    /* margin-right: 5px; */
}

.grr--header__historyButton button{
    /* margin-right: 20px; */
    width: 100%;
}

.grr-disabled-row {
  background-color: #F1F4F9;
}

.grr-disabled-row > td {
    cursor: default;
}

.GRRTable .anticon {
    margin: auto;
}

.GRRTable p {
    margin-bottom: 0;
}

thead > tr > .th--percent:after { 
    content: ',\A0%'; 
}
thead > tr > .th--mlnd:after {
    content: ', млн\A0$';
}