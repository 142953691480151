
.assets-content{
    padding: 30px 20px 40px 30px;
    background-color: #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.Mining--actionButtons {
    margin-top: 20px;
}

.Mining--actionButtons p {
    font-size: 18px;
    margin-bottom: 5px;
}

.Mining--actionButtons button {
    width: 80%;
    margin-top: 10px;
    width: 100%;
    border: none;
    box-shadow: none;
}

.Mining--actionButtons button:disabled {
    opacity: 0.3;
}

.Mining--actionButtons button:focus {
    box-shadow: none;
}

.mining--header__stageBlock {
    margin: 0 0 1rem 0;
    margin-right: 5px;
    justify-content: space-between;
}

.mining--header__historyButton {
    margin-right: 5px;
}

.mining--header__historyButton button{
    margin-right: 20px;
    width: 100%;
}

.MiningTable table {
    /*table-layout: fixed !important;*/
}

.MiningTable td{
    cursor: pointer;
}

.mining--header__stageBlock.col-9 .col-6 {
    flex: 0 0 auto !important;
    margin: 0 !important;
}

.mining-disabled-row {
    background-color: #F1F4F9;
  }
  
.mining-disabled-row > td {
      cursor: default;
  }

.MiningTable .anticon {
    margin: auto;
}

.MiningTable p {
    margin-bottom: 0;
}