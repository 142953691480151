
.grrHistory {
    margin: 100px 100px;
    padding: 20px 20px;
    background-color: white;
    border: 1px solid  rgb(210, 210, 210);
    border-radius: 5px;
}

.grrHistory p {
    margin-top: 7px;
}

.grrHistory button {
    margin-left: 10px;
}

.grrHistory--icons svg {
    margin-top: -6px;
    /*margin-right: 5px;*/
}

.grrHistory--back {
    margin-bottom: 10px;
    display: flex;
    justify-content: end;
}

.alert-block {
    position: absolute !important;
    bottom: 20px;
    width: 60%;
    text-align: center;
}

.grrHistoryModalSubTable {
    margin: 20px 20px;
}