
.agreementsTable {
    overflow-y: auto;
}

.agreements--actionButtons {
    margin-top: 20px;
}

.agreements--actionButtons p {
    font-size: 18px;
    margin-bottom: 5px;
}

.agreements--actionButtons button {
    width: 80%;
    margin-top: 10px;
    box-shadow: none;
}

.agreements--actionButtons button:focus {
    box-shadow: none;
}

.agreements--modal__addButton {
    margin-top: 2.3rem;
}

.agreements--typePanel button{
    /*margin-left: 8rem;*/
    margin-top: 1rem;
    height: 2.5rem;
}

.agreements--header button {
    /*margin-right: 6rem;*/
    height: 2.5rem;
}

.agreements--header__stageBlock {
    margin-bottom: 30px;
}

.agreementsTable--icons {
    font-size: 20px ;
}

.agreementsTable button {
    margin-left: 10px;
}