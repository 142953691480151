
.report-company-table{
    margin: 5px 0;
	width: auto;
    background-color: #fff;
    border: 1px solid #000;
    border-collapse: separate;
}

.report-company-table p {
    margin: 0;
    min-width: 520px;
}

.report-company-table td {
	border: none;
    min-width: 100px;
    padding: 0 10px;
}

.report-company-table .bold {
    font-weight:bold;
}

.report-company-table .small {
    font-weight: lighter;
    font-size: 12px;
    text-align: center;
}

.report-company-table .border-bottom {
    border-bottom: 2px solid black !important;
}

.report-company-table .border-top {
    border-top: 2px solid black !important;
}

.report-company-table .margin-top {
    height: 4rem;
    vertical-align: bottom;
}

.fixed {
    position: sticky;
    left: 0;
    z-index: 3;
    background-color: white;
}

.report-company--hidden {
    min-width: 30px;
    margin-left: -30px;
    height: auto;
    position: sticky;
    left: -30px;
    z-index: 2;
    background-color: #F1F4F9;
}

