.addReport{
    max-width: 1200px;
}

.addReport__hidden {
   display: none !important;
}

.btn-report-file {
    width: 100%;
    height: 2.5rem;
    margin: 10px 0;
}

.reports--addReportButton {
    display: flex;
    justify-content: end;
    margin: 1rem 0rem;
}

.report--icons {
    font-size: 20px;
}

.ReportTable button {
    margin-left: 10px;
}

.ReportTable svg {
    margin-right: 10px;
}
