.mainPage--Wrapper {
    height: 100%;
    width: auto;
    overflow-y: auto;
    justify-content: start;
}

.mainPage--Wrapper button {
    margin-top: 5px;
    width: 300px;
    float: right;
}

.mainPage--news {
    min-height: -webkit-fill-available;
    padding: 0 20px !important;
}

.mainPage--image {
    width: 100%;
    width: 20rem;
    margin: 5px 20px 5px 0;
    float:left
}

.mainPage--mainNews {
    padding-top: 20px;
}

.mainPage--mainNewsBlock {
    padding: 0 20px !important;
}

.mainPage--block {
    background-color: white;
    white-space: break-spaces;
}

.mainPage--icon {
    height: 20px;
    width: 20px;
    margin-right: 9px;
    margin-top: 3px;
}

.mainPage--icon2 {
    height: 20px;
    width: 20px;
    margin-right: 9px;
    margin-top: 7px;
}
.mainPage--item__header {
    margin-top: 20px;
}

.mainPage--item {
    margin-top: 10px;
    padding: 20px 20px;
    border: solid 1px #eee;
    border-radius: 5px;
}

.news-table {
    margin: 5px 0;
	width: 100%;
	margin-bottom: 20px;
	border: 1px solid #dddddd;
	border-collapse: collapse; 
}
.news-table th {
	font-weight:lighter;
	padding: 20px 0 5px 0;
    font-size: 12px;
	border: none;
}
.news-table td {
	border: none;
	padding: 5px;
}

.news-table .bold-td {
    font-weight:bold;
}

.news-table .small-td {
    font-weight: lighter;
    font-size: 12px;
    text-align: center;
}

.news-table .text-right {
    text-align: right;
    padding-right: 20px;
}

.news-table .left-th, .news-table .left-td {
    padding-left: 20px;
}

.news-table .lower-td {
    padding-bottom: 20px;
}

.mainPage--emptyNews {
    font-size: 20px;
    color: rgb(216, 216, 230);
    margin-left: 1.5rem;
}