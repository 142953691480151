.reports--graph {
    margin-bottom: 40px;
}

.reports--graph__block {
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
}

.reports--graph__row {
    display: flex;
    margin: 0 10px;
}

.reports--graph__value_block {
    height: 166px;
    margin-top: 10px;
    padding: 20px 20px;
}

.reports--graph__block--double {
    margin-top: 10px;
}

.reports--graph__block--donut {
    margin-top: 10px;
}

.reports--graph__block h5 {
    font-size: 18px;
    text-align: center;
}

.reports--graph__value_block h1 {
    text-align: center;
    color: #0070C0;
    font-size: 60px;
}

.reports--graph__block canvas {
    padding: 10px;
}