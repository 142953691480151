.list {
  padding-left: 30px;
}

.block {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 55px;
  grid-template-columns: auto 1fr;
}

.tabsWrap {
  display: grid;
  min-width: 410px;
  max-width: 500px;
  grid-row-gap: 30px;
  align-content: start;
}

.backButton {
  width: 100%;
  height: 55px;
  font-weight: 400;
  font-size: 16px;
  color: #5f5f5f;
  background: #ececec;
  border-radius: 3px;
}

.contentTitleBlock {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  grid-column-gap: 30px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 5px;
  margin-bottom: 13px;
}

.contentTitle {
  font-weight: 600;
  font-size: 28px;
}

.contentSubtitle {
  font-weight: 400;
  font-size: 12px;
  background: #f5f5f5;
  border-radius: 6px;
  padding: 5px 20px;
}

.content {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.contentInternalTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.contentItalic {
  font-weight: 600;
  font-style: italic;
}

.contentScheme {
  width: 100%;
  margin-bottom: 25px;
}

.contentScheme img {
  width: 100%;
}
