.mainHeader {
    /*padding: 0 0.5rem;*/
    background-color: #124778;
    height: 80px;
}

.mainHeader--IconBlock {
    /* max-height: 60px !important; */
    height: 80px;
    max-width: 230px !important;
    width: 230px;
    align-items: center;
    background-color: #1D5997;
}

.mainHeader--IconBlock--Logo {
    margin:auto;
    padding-right: 0.8rem;
    /*transform: scale(1.3);*/
}

.mainHeader--Block {
    align-items: center;
}

.mainHeader--userBlock {
    align-items: center;
}

.mainHeader--Timer {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    color: rgb(206, 236, 241);
}

.mainHeader--TimerTitle {
    font-size: 14px;
    max-width: 5rem;
    margin-left: 0.5rem;
    line-height: 1;
}

.mainheader--TimerIcon {
    /*margin-top: 5px;*/
    font-size: 32px;
}

.mainHeader--TimerValue {
    /*margin-left: 0.5rem;*/
    font-size: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mainHeader--TimerComment {
    font-size: 11px;
    margin: 0 0.065rem 0 0.3rem;
    display: flex;
    justify-content: space-between;
}

.mainHeader--TimerValue span:nth-of-type(1),
.mainHeader--TimerValue span:nth-of-type(2),
.mainHeader--TimerValue span:nth-of-type(3) {
    background-color: #13206A;
    padding: 0px 5px;
    border-radius: 8px;
    margin: 0 3px;
}

.mainHeader--round, .mainHeader--year {
    color: rgb(206, 236, 241);
    margin-left: 6rem;
    align-items: center;
}

.mainHeader--round p, .mainHeader--year p {
    margin-right: 8px;
    margin-bottom: 0;
    font-size: 14px;
}

.mainHeader--round span, .mainHeader--year span {
    font-size: 24px;
    margin-bottom: 3px;
    color: #fff;
}

.anticon {
    vertical-align: 0 !important;
}

.mainHeader--Block:has(.anticon-logout) {
    margin-right: 1rem;
}