.addNews {
    /* margin: 30px 100px; 
    max-width: 1200px;*/
}

.addNews--form {
    padding: 30px 30px;
    background-color: white;
    border: 1px solid rgb(210, 210, 210);
    border-radius: 5px;
}

.addNews .row {
    margin-top: 0.3rem;
}

.addNews .countryPanel--element {
    background-color: white;
    /*border: 1px solid #d9d9d9 !important;*/
    line-height: 20px;
    min-width: 6.5rem;
}
.addNews--newsTypeGroup {
    width: 100%;
}
.addNews--newsTypeElement {
    width: 25% !important;
    line-height: 20px;
    font-size: 16px;
    margin: 2px 0;
    height: 40px;
    padding: 10px 0;
    width: 10rem;
    text-align: center;
}
.addNews p {
    margin-top: 7px;
}

.addNews button {
    min-width: 150px;
}

.addNews--regionTypeGroup {
    width: 100%;
}

.addNews--submitButton {
    display: flex;
    justify-content: center;
}

.addNews--submitButton button {
    width: 6rem;
}

.addNews--form .countryPanel--element {
    width: 20%;
}

.addNews h5 {
    margin-top: 1.5rem;
}

.addNews-getNewsButton {
    margin-top: 3rem;
}

.addNews--newsViewElement {
    margin-top: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    background-color: #fff;
    padding: 5px 10px;
}

.addNews--newsViewElement h5 {
    margin-top: 5px;
}

.addNews--newsViewElement p {
    white-space: break-spaces;
}

.addNews--newsViewElement__button {
    margin-top: 10px;
}

.addNews--generalNews__checkbox {
    margin-top: 1rem;
}

.addNews--form .row {
    margin-top: 5px;
}

.addNews--genegalImage {
    width: 12rem;
    float: left;
    cursor: pointer;
}

.addNews--ImageBlock {
    max-height: 10rem;
    float: right;
}

.newsHistory--roundNumber {
    color: #999999;
    font-size: 14px;
    margin: 5px 0;
}