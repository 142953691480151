.map {
  position: relative;
}

.map img {
  max-width: 100%;
  max-height: 100%;
}

.mapButton {
  position: absolute;
  font-weight: 600;
  font-size: 15px;
  height: 25px;
  padding: 0px 15px;
  border-radius: 50px;
}

.Valhalla {
  top: 33%;
  left: 24%;
}

.Gardarika {
  top: 32%;
  left: 61%;
}

.Assyria {
  top: 55%;
  left: 28%;
}

.Parthia {
  top: 55%;
  left: 49%;
}

.Srivijaya {
  top: 74%;
  left: 21%;
}

.title {
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #7b7b7b;
  margin: 20px;
}
