.sideMenu {
  color: white !important;
  background-color: #182838 !important;
  width: 100%;
  min-width: 230px;
}

.sideMenu__item,
.list-group-item {
  padding: 0.8rem 1rem !important;
  color: rgba(255, 255, 255, 0.6) !important;
  background-color: #182838 !important;
  margin-bottom: 0;
  white-space: nowrap;
  cursor: pointer;
  border: none !important;
}

.sideMenu__item:hover {
  background-color: lightgray;
}

.sideMenu__sub__item {
  width: 100%;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6) !important;
  background-color: #182838 !important;
  /*padding: 0.5rem 0.5rem 0.5rem calc(1.5rem + 25px);*/
  /*border-bottom: 1px solid rgb(29, 27, 27) !important;*/
  border: none !important;
  white-space: pre-line;
  display: inline-flex !important;
}

.sideMenu__sub__item_hidden {
  border: 0 !important;
  height: 0 !important;
  padding: 0 !important;
}

.sideMenu__sub__item:hover {
  background-color: lightgray;
}

.sideMenu__item__icon {
  display: inline-block;
  width: 25px;
  margin-right: 1rem;
  text-align: center;
}

/*.sideMenu__item__active {
  text-decoration: underline;
  border-color: #374a60 !important;
}*/

/*.sideMenu__item.active {
  border-color: #374a60 !important;
}*/

.sideMenu__item__accordion_toggle {
  text-align: left;
  width: 100%;
  padding: 0;
  border: 0;
  /*border-bottom: 1px solid #374a60 !important;*/
}

.sideMenu__item__accordion_toggle:focus {
  outline: 0;
}

.sideMenu a {
  text-decoration: none;
  color: white;
}

.sideMenu a:hover {
  color: white;
}

.sideMenuItem__item__title {
  width: calc(100% - 4rem);
  font-size: 70%;
  padding-top: 0px;
  margin-left: 10px;
  opacity: 0.7;
  cursor: default;
}

.sideMenuItem__item__title:hover {
  transform: none !important;
}

.sideMenu__sub__item > .list-group-item:hover {
  background-color: #13202D  !important;
  color: #fff !important;
}

.sideMenu__sub__item:has(.sideMenu__item__active),
.sideMenu__item:has(.sideMenu__item__active) {
  background-color: #13202D !important;  
  color: #fff !important;
}