.panelButton {
  padding: 10px 20px;
}

.contentBlock {
  padding: 30px;
  background-color: #fff;
}


