.countryPanel--group {
    padding-left: 0 !important;
    display: flex;
    flex-wrap: nowrap !important;
}

.countryPanel--element {
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.05) 12.7%, rgba(0, 0, 0, 0) 42.06%), #EBEFF4;
    border: none;
    outline: none;
    margin: 0;
    height: 48px;
    font-size: 14px;
    line-height: unset;
    padding: 0;
    min-width: 8rem;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
}

.countryPanel--element .ant-radio-button{
    outline: none;
    background: none;
}

.countryPanel--element.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within,
.ant-radio-button-wrapper:focus-within{
    box-shadow: none;
}

.ant-radio-button-wrapper:first-child{
    border-left: none;
}

.ant-radio-button-wrapper:not(:first-child)::before{
    content: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #000;
    background: #fff;
    border: none;
    /* margin-top: -3px; */
    /* height: 53px; */
}

.ant-radio-button-wrapper:hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #202020;
}

/*.countryPanel--element:checked, .countryPanel--element::before, .countryPanel--element:active {
    border: none !important;
}*/