.assets-content{
    padding: 30px 20px 40px 30px;
    background-color: #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.ant-table-content {
    overflow-x: auto;
}

.licensingTable {
    overflow-y: auto;
}

.licensingTable td{
    cursor: pointer;
}

.licensing--actionButtons {
    margin-top: 20px;
}

.licensing--actionButtons p {
    font-size: 18px;
    margin-bottom: 5px;
}

.licensing--actionButtons button {
    width: 80%;
    margin-top: 10px;
    box-shadow: none;
}

.licensing--actionButtons button:focus {
    box-shadow: none;
}

.licensing--actionButtons button:disabled {
    opacity: 0.3;
}

.hiddenCol {
    display: none;
}

.licensing--modal__addButton {
    margin-top: 2.3rem;
}

.centeredCol {
    text-align: center !important;
}

.licensing--typePanel button{
    /*margin-left: 8rem;*/
    margin-top: 1rem;
    height: 2.5rem;
}

.licensing--header button {
    /*margin-right: 6rem;*/
    height: 2.5rem;
}

.licensing--header__stageBlock {
    margin-bottom: 30px;
}

.licensingHistory--icons {
    font-size: 20px;
}

.licensingHistoryModalTable button {
    margin-left: 10px;
}

.licensing--header__endRoundButton {
    min-width: 170px;
}

.licensing--actionModal--title {
    margin-bottom: 3rem;
}

.licensing--actionModal--myTeam {
    margin-bottom: 1.5rem;
}

.licensing--actionModal--myTeam__title {
    margin-top: 2.5rem;
}

.licensing--header__historyButton {
    /* margin-right: 5px; */
}

.licensing--header__historyButton button{
    /* margin-right: 20px; */
    width: 100%;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active .row {
    margin: 0 0 1.65rem 0;
    display: flex;
    justify-content: space-between;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active .col-6 {
    margin: 0 -0.75rem !important;
    max-width: 52% !important;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active:has(.licensing--actionModal--myTeam) .row {
    margin-right: calc(var(--bs-gutter-x) * -.5) !important;
    margin-left: calc(var(--bs-gutter-x) * -.5) !important;
}

.licensing-disabled-row {
    background-color: #F1F4F9;
  }
  
.licensing-disabled-row > td {
      cursor: default;
  }

.licensingTable .anticon {
    margin: auto;
}

.licensingTable p {
    margin-bottom: 0;
}