.stagePanel {
    display: flex;
    justify-content: left;
}

.stagePanel .ant-select-selector {
    background-color: #EBEFF4 !important;
    min-width: 8rem;
}

.stagePanel--group {
    padding-left: 0 !important;
    display: flex;
    flex-wrap: nowrap !important;
    background-color: #EBEFF4;
}

.stagePanel--element {
    background-color: #EBEFF4;
    margin: 2px 0;
    height: 40px;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0;
    
    text-align: center;
    
}