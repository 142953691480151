html,
body,
.root {
  height: 100%;
  background-color: #F1F4F9 !important;
  min-width: 1350px;
  overflow-x: auto;
  overflow-y: hidden;
}

* {
  box-sizing: border-box
} 

.basewrapper__body {
  padding: 30px 30px !important;
  background-color: #F1F4F9;
  width: auto !important;
  overflow-y: auto;
}

.sideMenu{
  height: 100%; 
  /*padding-left: 8px !important;*/
}

.basewrapper__container__col {
  background-color: #F1F4F9;
  padding: 0 !important;
  max-width: 230px !important;
}

.basewrapper__container {
  height: calc(100% - 80px);
  padding-right: 0 !important;
  box-sizing: border-box;
  padding-left: 0 !important;
  /*margin: 0 !important;*/
  /* width: 100%; */
}

.basewrapper__container__row {
  flex-wrap: nowrap !important;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0 !important;
}
