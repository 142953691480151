

.loginPage__form {
  width: 300px;
}

.loginPage__form button {
  margin-top: 1rem;
}

.loginPage__errorAlert {
  margin: 20px 0 0 0;
}

.loginPage__container {
  justify-content: center;  
  display: flex;
}

.loginPage__card {
  display: block;
  margin-left: -260px;
  height: max-content;
  margin-top: auto;
  margin-bottom: auto;
}
