.management {
    margin: 0px 0px;
}

.management--block {
    max-width: 1200px;
    padding: 10px 20px;
    /* margin: 0 10px; */
    background-color: white;
    border: 1px solid  rgb(210, 210, 210);
    border-radius: 5px;
}

.management--block .row {
    margin: 3px 0 !important;
    justify-content: flex-start !important;
}

.management p {
    margin-top: 1rem;
    margin-bottom: 0.7rem;
}

.management button, .management label {
    min-width: 180px;
    max-width: 240px;
}

.alert-block {
    position: absolute !important;
    bottom: 20px;
    width: 60%;
    text-align: center;
}

.management--block__status {
    text-align: center;
}

.management--block__hidden {
    display: none !important;
}

.management--block__fileBlock .btn {
    min-width: 150px;
    height: 2.5rem;
    margin: 0 5px;
}

.management--newGameButton {
    margin: 15px 0;
}

.management--block__modalRow {
    margin: 10px 0;
}