.Technologies--header svg{
    margin-top: -7px;
    padding-right: 5px;
}

.Technologies--tabs > .ant-tabs-nav {
    margin: 0;
}

.TechnologiesTable {
    margin-top: 20px
}

.TechnologiesTable--pointer td{
    cursor: pointer;
}

.TechnologiesTable tr th {
    padding: 10px 5px;
}

.TechnologiesTable tr td {
    padding: 2px 5px;
}

.Technologies--actionButtons {
    margin-top: 20px;
}

.Technologies--actionButtons p {
    font-size: 18px;
    margin-bottom: 5px;
}

.Technologies--actionButtons button {
    width: 80%;
    margin-top: 10px;
    box-shadow: none;
}

.Technologies--actionButtons button:focus {
    box-shadow: none;
}

.technologies--available, .technologies--implemented, .technologies--actived {
    background-color: white;
    padding: 20px 30px;
}

.technologies--available h4 {
    margin-left: 10px;
}
.technologies--available__select {
    width: calc(100% - 20px);
    font-size: 20px;
    margin: 0 10px;
}

.technologies--available__descriptionBlock {
    padding: 18px 24px;
    margin: 0 10px;
    margin-top: 20px;
    border: solid 1px #eee;
    border-radius: 5px;
}

.technologies--available button {
    width: 100%;
    margin-top: 2rem;
}

.technologies--available button:disabled {
    opacity: 0.3;
}

.technologies--available.col-5 {
    padding-right: 1.5rem;
}

.technologies--available--icon {
    height: 20px;
    width: 20px;
    margin-right: 20px;
    margin-top: 5px;
}

.technologies--available--item__header {
    margin-top: 40px;
}

.technologies--available--item {
    margin-top: 10px;
    padding: 5px;
    border: solid 1px #eee;
    border-radius: 5px;
}

.technology--modal__addButton {
    margin-top: 2.3rem;
}

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    font-size: inherit !important;
}

.technologies--actived button:disabled {
    opacity: 0.3;
}

.technologies--actived__buttons {
    display: flex;
    justify-content: right;
}

.technologies-disabled-row {
    background-color: #F1F4F9;
  }
  
.technologies-disabled-row > td {
      cursor: default;
  }

.TechnologiesTable .anticon {
    margin: auto;
}

.TechnologiesTable p {
    margin-bottom: 0;
}
