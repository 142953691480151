.descrPanel {
  display: grid;
  grid-row-gap: 10px;
}

.descrPanelElement {
  background-color: #ebeff4;
  margin: 2px 0;
  height: 55px;
  display: grid;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 0;
  min-width: 8rem;
  text-align: center;
  font-weight: 400;
  color: #5f5f5f;
  background: #ffffff;
  border: 1px solid #ececec !important;
  border-radius: 3px !important;
}

.descrPanelElement::before {
  display: none !important;
}

.wikiButtonChecked {
  border: 2px solid #5592D0 !important;
  box-shadow: 0px 4px 20px rgba(29, 89, 151, 0.3);
}
