.block {
  max-width: 1150px;
}

.mainTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 27px;
}

.group {
  margin-bottom: 20px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 5px;
  margin-bottom: 13px;
}

.content {
  font-weight: 400;
  font-size: 16px;
}

.businessWrap {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}
