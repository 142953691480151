.accept {
    /* margin: 100px 100px; */
}

.accept--block {
    padding: 20px 20px;
    background-color: white;
    border: 1px solid  rgb(210, 210, 210);
    border-radius: 5px;
}

.accept--block .ant-tabs-nav{
    margin-bottom: 0 !important;
}

.accept p {
    margin-top: 7px;
}

.alert-block {
    position: absolute !important;
    bottom: 20px;
    width: 60%;
    text-align: center;
}

/*   Для Лицензирования  */
.acceptLicensingHistoryModalSubTable {
    margin: 20px 20px;
}

.accept--action_col {
    min-width: 180px;
}

.acceptLicensing--sendBlock {
    display: flex;
    justify-content: end;
    margin: 10px 10px;
}

.acceptLicensingCenterTable table th, .acceptLicensingCenterTable table td,
.acceptLicensingLowerTable table th, .acceptLicensingLowerTable table td,
.acceptTechnologyLowerTable table th, .acceptTechnologyLowerTable table td {
    background-color: #f7f7f7;
}

.acceptLicensingCenterTable table .maxActionRow th, 
.acceptLicensingCenterTable table .maxActionRow td,
.acceptTechnologyLowerTable table .maxActionRow th, 
.acceptTechnologyLowerTable table .maxActionRow td {
    background-color: rgb(190, 255, 190);
}

tr.maxActionRow:hover > td,
tr.maxActionRow > td.ant-table-cell-row-hover {
    background-color: rgb(148, 255, 148) !important;
}